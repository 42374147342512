//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportMixin from "@/mixins/Report";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import CategoryProductsTable from "@/components/Category/ProductsTable.vue";
import CategoryBrandsTable from "@/components/Category/BrandsTable.vue";
import CategorySellersTable from "@/components/Category/SellersTable.vue";
import CategorySubcategoriesTable from "@/components/Category/SubcategoriesTable.vue";
import CategoryKeywordsTable from "@/components/Category/KeywordsTable.vue"; //kwd
import CategorySegmentsTable from "@/components/Category/SegmentsTable.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
import ListAddItemButton from "@/components/List/AddItemButton.vue";
import CategoryPath from "@/components/Category/Path";
import SwitchInput from "@/components/SwitchInput.vue";
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import LoadingOverlay from "@/components/Loading/Overlay";
import CategoryInfoWeakPlan from "@/components/CategoryInfoWeakPlan.vue";
const tableComponents = {
  products: CategoryProductsTable,
  brands: CategoryBrandsTable,
  sellers: CategorySellersTable,
  subcategories: CategorySubcategoriesTable,
  keywords: CategoryKeywordsTable,
  //kwd
  segments: CategorySegmentsTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$category;
    const name = (_this$category = this.category) === null || _this$category === void 0 ? void 0 : _this$category.c_name;
    return {
      title: name ? `Обзор показателей категории ${name}` : undefined
    };
  },
  data() {
    let cells = getEntityCellsConfig("category");
    const customCells = {
      avgPerDay: {
        title: "Среднее<br>продаж в день",
        multi: [{
          id: "avg_sold",
          unit: cells.avg_sold.unit
        }, {
          id: "avg_revenue",
          unit: cells.avg_revenue.unit
        }],
        color: cells.avg_sold.color
      },
      //&:
      losses: {
        title: `Упущенная выручка`,
        //color: cells.losses.color,    //!пока не используем losses для категорий
        color: "pink",
        multi: [{
          id: "losses",
          //unit: cells.losses.unit,
          unit: "руб"
        }]
      }
    };
    delete cells.avg_sold;
    delete cells.avg_revenue;
    cells = {
      ...cells,
      ...customCells
    };
    const doughnutChartBackgroundColors = ["#AFC9D2",
    // Lighter Shade of Steel Blue
    "#FFC88F",
    // Lighter Shade of Orange
    "#8C7AA5",
    // Lighter Shade of Slate Purple
    "#78B7AD",
    // Lighter Shade of Aquamarine
    "#C47CA7",
    // Lighter Shade of Red Violet
    "#FAB895",
    // Lighter Shade of Coral
    "#406B80",
    // Lighter Shade of Dark Cerulean
    "#B5DCA7",
    // Lighter Shade of Olive Green
    "#C9CC90",
    // Lighter Shade of Olive
    "#816E4A",
    // Lighter Shade of Dark Brown
    "#F3C4CF" // Lighter Shade of Light Pink
    ];

    return {
      category: null,
      tabs_tables_all: [{
        id: "products",
        title: "Товары"
      }, {
        id: "brands",
        title: "Бренды"
      }, {
        id: "sellers",
        title: "Продавцы"
      }, {
        id: "subcategories",
        title: "Подкатегории"
      },
      /* //&: 2022-02-14 : api_CH пока не реализован
              {
                  id: 'segments',
                  title: 'Ценовая сегментация'
              },
              */

      //kwd
      {
        id: "keywords",
        title: "Поисковые запросы"
      }],
      tabs_tables_model: null,
      noDynamic: false,
      cells,
      cacheLoaded: false,
      chartDataBrands: {
        labels: [],
        datasets: [{
          backgroundColor: doughnutChartBackgroundColors,
          borderWidth: 0,
          data: []
        }]
      },
      chartDataProducts: {
        labels: [],
        datasets: [{
          backgroundColor: doughnutChartBackgroundColors,
          borderWidth: 0,
          data: []
        }]
      },
      chartDataSellers: {
        labels: [],
        datasets: [{
          backgroundColor: doughnutChartBackgroundColors,
          borderWidth: 0,
          data: []
        }]
      },
      refresh: 0,
      filters: null,
      loadedCategoryShareTotalRevenue: false,
      newTabs: [{
        id: 3,
        title: "Анализ монополизации",
        new: true
      }],
      activeTab: null,
      cacheErrorMessage: null,
      limitError: false
    };
  },
  methods: {
    openRegisterModal() {
      if (this.notRegister) {
        this.$modal.open(() => import("@/components/modals/access/ModalRegister"), {
          message: "Данный функционал доступен только для зарегистрированных пользователей"
        });
      }
    },
    limitReached(message) {
      this.limitError = true;
      this.$modal.open(() => import("@/components/modals/access/ModalLimitReachedTemplate"), {
        alert: "Превышен лимит на вашем тарифном плане",
        title: message
      });
    },
    getTableComponent(id) {
      return tableComponents[id];
    },
    getTableId() {
      return this._uid;
    },
    updateActiveTab(tab) {
      this.activeTab = tab;
    },
    async loadCategoryAnalyticCache(v) {
      this.cacheLoaded = false;
      try {
        const result = await this.$store.dispatch("category/categoryAnalyticCache", {
          ...v
        });
        this.cacheLoaded = true;
        return result;
      } catch (e) {
        const errorMessage = e.message;
        if (typeof errorMessage === "string" && errorMessage !== "no-limit-error") {
          this.limitReached(errorMessage);
          this.cacheErrorMessage = errorMessage;
        }
      }
    },
    async loadCategory(v) {
      this.category = await this.$store.dispatch("category/getCategory", {
        ...v
      });
    },
    async loadCategoryShareTotalRevenue(v) {
      this.loadedCategoryShareTotalRevenue = false;
      const {
        data
      } = await this.$store.dispatch("category/categoryShareTotalRevenue", {
        ...v,
        table: this.tabs_tables_model.id
      });
      //console.log(data);
      const brandsData = data.brands.map(item => item.revenue);
      this.chartDataBrands.labels = data.brands.map((item, index) => `${item.title} ${brandsData[index]}%`);
      this.chartDataBrands.datasets[0].data = brandsData;
      const productsData = data.products.map(item => item.revenue);
      this.chartDataProducts.labels = data.products.map((item, index) => `${this.truncateString(item.title, 45)} ${productsData[index]}%`);
      this.chartDataProducts.datasets[0].data = productsData;
      const sellersData = data.sellers.map(item => item.revenue);
      this.chartDataSellers.labels = data.sellers.map((item, index) => `${item.title} ${sellersData[index]}%`);
      this.chartDataSellers.datasets[0].data = sellersData;
      this.refresh++;
      this.loadedCategoryShareTotalRevenue = true;
    },
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    loadOverview() {
      return this.$store.dispatch("category/categoryOverviewAll", {
        ...this.reportSettingsWithFilters,
        table: this.tabs_tables_model.id
      });
    },
    updateFilters(val) {
      this.filters = val;
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "left",
          labels: {
            usePointStyle: true,
            fontFamily: "monospace",
            fontSize: 14,
            padding: 20,
            fontColor: this.$store.getters.themeClass === "dark" ? "#ffffff" : "#000000"
          }
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const datasetLabel = data.labels[tooltipItem.index];
              return `${datasetLabel}`;
            }
          }
        }
      };
    },
    currentSubscriptions() {
      return this.$access.getSubscriptions();
    },
    user() {
      return this.$store.state.user.user;
    },
    notRegister() {
      return !this.user && !this.currentSubscriptions.length;
    },
    hasFreePlan() {
      return this.user && !this.currentSubscriptions.length;
    },
    hasStartPlan() {
      return this.currentSubscriptions.length && this.currentSubscriptions.every(sub => sub.plan.name === "Start");
    },
    myStylesBrands() {
      const longestLabel = this.chartDataBrands.labels.reduce((longest, current) => {
        return current.length > longest.length ? current : longest;
      }, "");
      const width = longestLabel.length * 8 + 300;
      return {
        minWidth: `${width}px`,
        height: "400px"
      };
    },
    myStylesProducts() {
      const longestLabel = this.chartDataProducts.labels.reduce((longest, current) => {
        return current.length > longest.length ? current : longest;
      }, "");
      const width = longestLabel.length * 8 + 300;
      return {
        minWidth: `${width}px`,
        height: "400px"
      };
    },
    myStylesSellers() {
      const longestLabel = this.chartDataSellers.labels.reduce((longest, current) => {
        return current.length > longest.length ? current : longest;
      }, "");
      const width = longestLabel.length * 8 + 300;
      return {
        minWidth: `${width}px`,
        height: "400px"
      };
    },
    tabs_tables() {
      return this.tabs_tables_all.filter(tab => {
        if (tab.id === "subcategories") {
          var _this$category2;
          return (_this$category2 = this.category) === null || _this$category2 === void 0 ? void 0 : _this$category2.qHasChild;
        }
        return true;
      });
    },
    track() {
      var _this$category3;
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / Анализ категории / ${this.tabs_tables_model.title}`,
        name: (_this$category3 = this.category) === null || _this$category3 === void 0 ? void 0 : _this$category3.c_name,
        date: this.reportSettings.date,
        date2: this.reportSettings.date2
      };
    },
    fileName() {
      var _this$category4;
      return `${this.$route.params.mp.toUpperCase()} Категория ${(_this$category4 = this.category) === null || _this$category4 === void 0 ? void 0 : _this$category4.c_name} ${this.tabs_tables_model.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const reportSettings = {
        mp: this.$route.params.mp,
        c_id: this.$route.params.c_id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        ...this.$route.query
      };
      return reportSettings;
    },
    reportSettingsWithFilters() {
      if (!this.reportSettings) {
        return null;
      }
      const result = {
        ...this.reportSettings
      };
      if (this.filters) {
        result.filters = this.filters;
      }
      return result;
    },
    categoryKey() {
      return `${this.$route.params.c_id}:${this.$route.params.mp}`;
    },
    tableKey() {
      return `${this.categoryKey}:${this.tabs_tables_model.id}`;
    }
  },
  watch: {
    $route: {
      handler() {
        this.openRegisterModal();
      },
      deep: true,
      immediate: true
    },
    reportSettings: {
      async handler(v, oldV) {
        if (!v) {
          return;
        }
        const paramsChanged = oldV ? v.mp !== oldV.mp || v.c_id !== oldV.c_id || v.date !== oldV.date || v.date2 !== oldV.date2 || v.fbs !== oldV.fbs : false;
        if (!oldV || paramsChanged) {
          this.loadCategory(v);
          await this.loadCategoryAnalyticCache(v);
          this.loadCategoryShareTotalRevenue(v);
        }
      },
      immediate: true
    },
    async reportSettingsWithFilters(v) {
      // const paramsChanged = oldV ? v.filters !== oldV.filters : false;
      // if (paramsChanged) {
      await this.loadCategoryShareTotalRevenue(v);
      // }
    },

    tabs_tables_model(v) {
      this.noDynamic = v.id === "subcategories";
    }
  },
  components: {
    MainTitle,
    AppTabs,
    DataTableActions,
    CategoryProductsTable,
    EntityCharts,
    ReportPage,
    ListAddItemButton,
    CategoryPath,
    SwitchInput,
    DoughnutChart,
    LoadingOverlay,
    CategoryInfoWeakPlan
  }
};